<template>
  <div
    id="DiffView"
    class="list-revisions col-sm"
  >
    <div
      v-show="isUpdating"
      class="container"
    >
      <div class="row">
        <div id="loading">
          <ul class="bokeh">
            <li />
            <li />
            <li />
          </ul>
        </div>
      </div>
    </div>
    <div v-html="diff2html" />
  </div>
</template>
<script>
import Vue from 'vue'

export default {
  name: 'DiffView',
  data () {
    return {
      diff2html: '',
      isUpdating: false
    }
  },
  mounted: function () {
    var self = this

    var left = self.$route.params.l
    var right = self.$route.params.r
    var branch = self.$route.params.branch
    var repo = self.$route.params.repo
    console.log(branch)
    self.isUpdating = !self.isUpdating

    Vue.axios.post('/api/diff-view', {
      left: left,
      right: right,
      repo: decodeURIComponent(repo),
      branch: branch
    })
      .then(function (response) {
        self.isUpdating = !self.isUpdating
        if (response.data.data && response.data.data.message) {
          self.diff2html = response.data.data.message
        } else {
          self.diff2html = '<p>Cannot perform diff at this moment</p>'
        }
        return true
      })
      .catch(function (err) {
        self.isUpdating = !self.isUpdating
        console.log(err)
      })
  }
}
</script>
<style lang="css">
</style>
