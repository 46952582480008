<template>
  <div id="Signout" />
</template>
<script>
export default {
  name: 'Signout',
  data () {
    return {}
  },
  activated () {
    this.$router.replace('/')
    this.$store.dispatch('closeSession')
  }
}
</script>
<style lang="css">
</style>
