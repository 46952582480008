import Vue from 'vue'
import Clipboard from 'v-clipboard'
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'moment'
import store from './store'
import router from './router.js'

import './favicon.ico'

Vue.use(Clipboard)
Vue.use(VueAxios, axios)

Vue.filter('niceTime', function (item) {
  try {
    return item ? moment(item).fromNow() : ''
  } catch (e) {
    /* handle error */
    console.error(e)
    return ''
  }
})

import App from './app.vue'

/* eslint-disable no-unused-vars */
let Md2HtmlApp = new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App)
})
