<template>
  <div id="App">
    <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
      <router-link
        class="navbar-brand"
        title="show revision list"
        :to="{ name:'home' }"
      >
        <img
          class="logo"
          src="images/logo.svg"
          alt="logo"
        >
        <span>STANDARDSHUB MD2HTML</span>
      </router-link>
      <button
        type="button"
        class="navbar-toggler"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click.prevent="toggleMenyClick"
      >
        <span class="navbar-toggler-icon" />
      </button>
      <div
        id="navbarCollapse"
        :class="{show: showMeny}"
        class="collapse navbar-collapse"
      >
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              target="_blank"
            >Guidelines</a>
          </li>
          <li
            v-show="havePremission({role: 'admin'})"
            :class="{ active: isActiveLink('repositories') }"
            title="show Repositories page"
            class="nav-item"
            @click.prevent="toggleMenyClick"
          >
            <router-link
              :to="{ name:'repositories' }"
              class="nav-link"
            >
              <i class="fab fa-github" />
              <span> Repositories</span>
            </router-link>
          </li>
          <li
            v-show="havePremission({role: 'admin'})"
            :class="{ active: isActiveLink('users') }"
            title="show Users page"
            class="nav-item"
            @click.prevent="toggleMenyClick"
          >
            <router-link
              class="nav-link"
              :to="{ name: 'users' }"
            >
              <i class="fas fa-users" />
              <span> Users</span>
            </router-link>
          </li>
          <li
            v-show="havePremission({role: 'admin'})"
            :class="{ active: isActiveLink('invitations') }"
            title="show Invitations page"
            class="nav-item"
            @click.prevent="toggleMenyClick"
          >
            <router-link
              class="nav-link"
              :to="{ name: 'invitations' }"
            >
              <i class="fas fa-mail-bulk" />
              <span> Invitations</span>
            </router-link>
          </li>
        </ul>
        <ul class="navbar-nav ml-auto pr-3">
          <li class="nav-item">
            <a
              class="nav-link"
              href="mailto:helpdesk@standardshub.io?subject=MD2HTML TOOL user inquiry"
              title="Send email to StandardsHub help desk"
            >Contact</a>
          </li>
        </ul>
        <div class="">
          <div
            v-show="!isLoggedIn()"
            @click.prevent="toggleMenyClick"
          >
            <router-link
              :to="{ name:'signin' }"
              title="sign in to administrative portal"
            >
              SignIn
            </router-link>
          </div>
          <div
            v-show="isLoggedIn()"
            @click.prevent="toggleMenyClick"
          >
            <router-link
              :to="{ name:'signout' }"
              title="sign out from administrative portal"
            >
              SignOut
            </router-link>
          </div>
        </div>
      </div>
    </nav>
    <div class="main container">
      <div class="row">
        <keep-alive>
          <router-view />
        </keep-alive>
      </div>
    </div>
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col">
            <span class="text-muted">StandardsHub MD2HTML tool</span>
            <span class="text-muted far fa-copyright" /> <span class="text-muted">2023</span>
          </div>
          <div class="col text-center">
            <span class="text-muted">Content gets automaticaly updated</span>
          </div>
          <div class="col text-right">
            <span class="text-muted">version:</span> <span class="text-muted">2.1.0</span>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  name: 'App',
  data () {
    return {
      showMeny: false
    }
  },
  computed: {
  },
  methods: {
    isLoggedIn () {
      return !!this.$store.getters.currentUser
    },
    havePremission (obj) {
      var user = this.$store.getters.currentUser
      return (user && user.role === obj.role)
    },
    isActiveLink (linkName) {
      return this.$route.name === linkName
    },
    toggleMenyClick () {
      this.showMeny = !this.showMeny
    }
  }
}
</script>
<style lang="css">
</style>
