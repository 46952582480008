<template>
  <div
    id="Users"
    class="col"
  >
    <h2>Users</h2>
    <div class="container">
      <div
        v-show="updateType"
        class="row"
      >
        <div class="col">
          <form
            class="validate-form"
            @submit.prevent="onUpdateUser"
          >
            <div class="form-group">
              <h4>Change pasword for: {{ updateEmail }}</h4>
            </div>
            <div class="form-group">
              <input
                id="updatePassword"
                v-model="updatePassword"
                class=""
                required
                type="password"
                value=""
                name="updatePassword"
                placeholder="password"
                autocomplete="new-password"
              >
            </div>
            <div class="form-group">
              <input
                id="updatePasswordCopy"
                v-model="updatePasswordCopy"
                class=""
                required
                type="password"
                value=""
                name="updatePasswordCopy"
                placeholder="confirm password"
                autocomplete="new-password"
              >
            </div>
            <div class="form-group">
              <span
                class="btn btn-link"
                @click.prevent="onCancelUpdate"
              >Cancel</span> or
              <input
                class="btn  btn-info"
                type="submit"
                value="Update"
              >
            </div>
          </form>
        </div>
      </div>
    </div>
    <table
      v-show="!updateType"
      class="table mt-3"
    >
      <thead>
        <tr>
          <th><i class="fas fa-at" /> email</th>
          <th><i class="fas fa-user-tag" /> role</th>
          <th><i class="fab fa-nintendo-switch" /> active</th>
          <th><i class="fas fa-toolbox" /> actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in users"
          :key="item.id"
        >
          <td>{{ item.email }}</td>
          <td>{{ item.role }}</td>
          <td>
            <button
              v-show="!item.active"
              class="btn btn-outline-danger"
              title="activate/deactivate user"
              @click.prevent="onActivateUser(item)"
            >
              <i class="fas fa-toggle-off" />
            </button>
            <button
              v-show="item.active"
              class="btn btn-outline-success"
              title="activate/deactivate user"
              @click.prevent="onDeactivateUser(item)"
            >
              <i class="fas fa-toggle-on" />
            </button>
          </td>
          <td>
            <button
              class="btn btn-info"
              title="show User info"
              :disabled="!canEditUser(item.id)"
              @click.prevent="onEditUser(item)"
            >
              <i class="fas fa-edit" />
            </button>
            <button
              class="btn btn-danger float-right"
              title="delete User"
              :disabled="!canDeleteUser(item.id)"
              @click.prevent="onDeleteUser(item)"
            >
              <i class="fas fa-trash-alt" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import Vue from 'vue'

export default {
  name: 'Users',
  data () {
    return {
      users: [],
      updateId: '',
      updateEmail: '',
      updatePassword: '',
      updatePasswordCopy: '',
      updateRole: '',
      updateType: ''
    }
  },
  activated: function () {
    this.updateType = ''
    this.updateEmail = ''
    this.updatePassword = ''
    this.updatePasswordCopy = ''
    this.fetchData()
  },
  methods: {
    clearForm: function () {
      this.updateId = ''
      this.updateEmail = ''
      this.updateRole = ''
      this.updatePassword = ''
      this.updatePasswordCopy = ''
    },
    onActivateUser: function (user) {
      if (user) {
        user.active = true
        this.updateUser(user)
      }
    },
    onDeactivateUser: function (user) {
      if (user) {
        user.active = false
        this.updateUser(user)
      }
    },
    canEditUser: function (userId) {
      return (this.$store.getters.currentUser && userId === this.$store.getters.currentUser.id)
    },
    canDeleteUser: function (userId) {
      return (this.$store.getters.currentUser && userId !== this.$store.getters.currentUser.id)
    },
    onUpdateUser: function () {
      if (this.canEditUser(this.updateId)) {
        var self = this

        Vue.axios({
          url: '/api/users/' + this.updateId,
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + self.$store.getters.currentUser.token,
            'Content-Type': 'application/json'
          },
          data: {
            id: self.updateId,
            email: self.updateEmail,
            password: self.updatePassword
          }
        })
          .then(function () {
            self.updateType = ''
            return self.fetchData()
          })
          .catch(function (e) {
            self.updateType = ''
            console.log(e.message)
          })
      } else {
        this.updateType = ''
      }
    },
    onCancelUpdate: function () {
      this.clearForm()
      this.updateType = ''
    },
    onEditUser: function (user) {
      this.updateType = 'edit'
      this.updateId = user.id
      this.updateEmail = user.email
      this.updateRole = user.role
      this.updatePassword = ''
      this.updatePasswordCopy = ''
    },
    onDeleteUser: function (user) {
      if (user) {
        console.log('deleting user: ', user)
        var self = this

        Vue.axios({
          url: '/api/users/' + user.id,
          method: 'DELETE',
          headers: {
            'Authorization': 'Bearer ' + self.$store.getters.currentUser.token,
            'Content-Type': 'application/json'
          }
        })
          .then(function (result) {
            console.log(result)
            return self.fetchData()
          })
          .catch(function (e) {
            console.log(e.message)
          })
      }
    },
    fetchData: function () {
      var self = this

      Vue.axios({
        url: '/api/users',
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + self.$store.getters.currentUser.token,
          'Content-Type': 'application/json'
        }
      })
        .then(function (result) {
          if (result.data) {
            if (result.data.data) {
              self.users = result.data.data.users
            } else if (result.data.errors) {
              self.errors = result.data.errors
            } else {
              console.log('this should not happen !?')
            }
          }
          return true
        })
        .catch(function (e) {
          console.log(e.message)
        })
    },
    updateUser: function (user) {
      if (user) {
        let self = this

        Vue.axios({
          url: '/api/users/' + user.id,
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + self.$store.getters.currentUser.token,
            'Content-Type': 'application/json'
          },
          data: user
        })
          .then(function (result) {
            console.log(result)
            return self.fetchData()
          })
          .catch(function (e) {
            console.log(e.message)
            self.fetchData()
          })
      }
    }
  }
}
</script>
<style lang="css">
</style>
