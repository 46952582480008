<template>
  <div
    id="Invitations"
    class="col"
  >
    <h2>Invitations</h2>
    <div class="container">
      <div
        v-show="!showInvitationForm && !showInvitationURLForm"
        class="row"
      >
        <div class="col">
          <button
            class="btn btn-info float-right"
            title="add new invitation"
            @click.prevent="showInvitationFormClick"
          >
            <i class="fas fa-plus-circle" />
          </button>
        </div>
      </div>
      <div
        v-show="showInvitationForm"
        class="row"
      >
        <div class="media col">
          <div class="media-body">
            <h5>New Invitation</h5>
            <form
              @submit.prevent="sendInvitationClick"
            >
              <div class="form-group">
                <input
                  id="invitation-email"
                  v-model="invitationEmail"
                  class="form-control"
                  required
                  type="text"
                  value=""
                  name="invitation-email"
                  placeholder="email"
                  autocomplete="user"
                >
              </div>
              <div class="form-group">
                <textarea
                  id="invitations-message"
                  v-model="invitationMessage"
                  class="form-control"
                  rows="10"
                  value=""
                  name="invitation-message"
                  placeholder="message"
                />
              </div>
              <div class="form-group">
                <span
                  class="btn btn-link"
                  @click.prevent="showInvitationFormClick"
                >Cancel
                </span>
                <input
                  class="btn  btn-info float-right"
                  type="submit"
                  value="Send"
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="showInvitationURLForm"
      class="row"
    >
      <div class="media col">
        <div class="media-body">
          <h4>Invitation: {{ getInvitationID() }}</h4>
          <div class="input-group mb-3 pt-3">
            <div class="input-group-prepend">
              <span
                class="input-group-text"
              >
                <i class="fas fa-at" />&nbsp; email
              </span>
            </div>
            <div class="form-control">
              {{ getInvitationEmail() }}
            </div>
          </div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fas fa-link" />&nbsp; link
              </span>
            </div>
            <div class="form-control">
              {{ getInvitationURL() }}
            </div>
            <div class="input-group-append">
              <button
                v-clipboard="() => getInvitationURL()"
                class="input-group-text"
                title="copy to clipboard"
              >
                <i class="fas fa-copy" />
              </button>
            </div>
          </div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fas fa-hourglass-half" />&nbsp; expire
              </span>
            </div>
            <div class="form-control">
              {{ getSelectedItemValidTo() }}
            </div>
          </div>
          <span
            class="btn btn-link"
            @click.prevent="hideInvitation"
          >Cancel</span>
        </div>
      </div>
    </div>
    <table
      v-show="showInvitationTable"
      class="table mt-3"
    >
      <thead>
        <tr>
          <th><i class="fas fa-at" /> email</th>
          <th><i class="fas fa-thermometer-half" /> status</th>
          <th><i class="fas fa-hourglass-half" /> expire</th>
          <th><i class="fas fa-history" /> updated </th>
          <th><i class="fas fa-toolbox" /> actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in invitations"
          :key="item.id"
        >
          <td>{{ item.email }}</td>
          <td>{{ item.status }}</td>
          <td>{{ item.validTo | niceTime }}</td>
          <td>{{ item.updatedAt | niceTime }}</td>
          <td>
            <button
              class="btn btn-warning"
              title="resend invitation"
              :disabled="canResendInvitation(item)"
              @click.prevent="resendInvitation(item)"
            >
              <i class="fas fa-retweet" />
            </button>
            <button
              class="btn btn-info"
              title="show invitation"
              :disabled="canResendInvitation(item)"
              @click.prevent="showInvitation(item)"
            >
              <i class="fas fa-eye" />
            </button>
            <button
              title="delete invitation"
              class="btn btn-danger float-right"
              @click.prevent="deleteInvitation(item)"
            >
              <i class="fas fa-trash-alt" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import Vue from 'vue'

export default {
  name: 'Invitations',
  data () {
    return {
      showInvitationForm: false,
      showInvitationTable: true,
      showInvitationURLForm: false,
      invitationEmail: '',
      invitationMessage: '',
      invitations: [],
      selectedItem: null
    }
  },
  activated () {
    this.clearForm()
    this.fetchData()
  },
  deactivated () {
  },
  methods: {
    getInvitationID () {
      return this.selectedItem ? this.selectedItem.id : ''
    },
    getInvitationURL () {
      return this.selectedItem ? window.location.origin + '/#/signup?token=' + this.selectedItem.token : ''
    },
    getInvitationEmail () {
      return this.selectedItem ? this.selectedItem.email : ''
    },
    getSelectedItemValidTo () {
      return this.selectedItem ? this.selectedItem.validTo : ''
    },
    canResendInvitation (value) {
      return 'accepeted' === value.status
    },
    clearForm () {
      this.invitationEmail = ''
      this.invitationMessage = ''
    },
    toggleShotForm () {
      this.showInvitationForm = !this.showInvitationForm
      this.showInvitationTable = !this.showInvitationTable
      if (this.showInvitationForm) {
        this.clearForm()
      }
    },
    showInvitationFormClick () {
      this.toggleShotForm()
    },
    sendInvitationClick () {
      this.toggleShotForm()
      this.sendInvitation()
    },
    sendInvitation () {
      var self = this
      Vue.axios({
        url: '/api/invitations',
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + self.$store.getters.currentUser.token,
          'Content-Type': 'application/json'
        },
        data: {
          email: self.invitationEmail,
          message: self.invitationMessage
        }
      })
        .then(result => {
          console.log(result.data)
          return self.fetchData()
        })
        .catch(e => {
          console.log(e.message)
        })
    },
    resendInvitation (value) {
      var self = this
      Vue.axios({
        url: '/api/invitations/' + value.id,
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer ' + self.$store.getters.currentUser.token,
          'Content-Type': 'application/json'
        }
      })
        .then(result => {
          console.log(result.data)
          return self.fetchData()
        })
        .catch(e => {
          console.log(e.message)
        })
    },
    showInvitation (item) {
      this.showInvitationURLForm = true
      this.showInvitationTable = false
      this.selectedItem = item
    },
    hideInvitation () {
      this.showInvitationURLForm = false
      this.showInvitationTable = true
      this.selectedItem = null
    },
    deleteInvitation (value) {
      var self = this
      Vue.axios({
        url: '/api/invitations/' + value.id,
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + self.$store.getters.currentUser.token,
          'Content-Type': 'application/json'
        }
      })
        .then(result => {
          console.log(result.data)
          return self.fetchData()
        })
        .catch(e => {
          console.log(e.message)
        })
    },
    fetchData () {
      var self = this

      Vue.axios({
        url: '/api/invitations',
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + self.$store.getters.currentUser.token,
          'Content-Type': 'application/json'
        }
      })
        .then(result => {
          if (result.data) {
            if (result.data.data) {
              self.invitations = result.data.data.invitations
            } else if (result.data.errors) {
              self.errors = result.data.errors
            } else {
              console.log('this should not happen !?')
            }
          }
          return true
        })
        .catch(e => {
          console.log(e.message)
        })
    }
  }
}
</script>
<style lang="css">
</style>
