var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-revisions col-sm", attrs: { id: "DiffView" } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isUpdating,
              expression: "isUpdating",
            },
          ],
          staticClass: "container",
        },
        [_vm._m(0)]
      ),
      _vm._v(" "),
      _c("div", { domProps: { innerHTML: _vm._s(_vm.diff2html) } }),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { attrs: { id: "loading" } }, [
        _c("ul", { staticClass: "bokeh" }, [
          _c("li"),
          _vm._v(" "),
          _c("li"),
          _vm._v(" "),
          _c("li"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }