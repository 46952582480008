var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col", attrs: { id: "Invitations" } }, [
    _c("h2", [_vm._v("Invitations")]),
    _vm._v(" "),
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showInvitationForm && !_vm.showInvitationURLForm,
              expression: "!showInvitationForm && !showInvitationURLForm",
            },
          ],
          staticClass: "row",
        },
        [
          _c("div", { staticClass: "col" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-info float-right",
                attrs: { title: "add new invitation" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.showInvitationFormClick.apply(null, arguments)
                  },
                },
              },
              [_c("i", { staticClass: "fas fa-plus-circle" })]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showInvitationForm,
              expression: "showInvitationForm",
            },
          ],
          staticClass: "row",
        },
        [
          _c("div", { staticClass: "media col" }, [
            _c("div", { staticClass: "media-body" }, [
              _c("h5", [_vm._v("New Invitation")]),
              _vm._v(" "),
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.sendInvitationClick.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.invitationEmail,
                          expression: "invitationEmail",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "invitation-email",
                        required: "",
                        type: "text",
                        value: "",
                        name: "invitation-email",
                        placeholder: "email",
                        autocomplete: "user",
                      },
                      domProps: { value: _vm.invitationEmail },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.invitationEmail = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.invitationMessage,
                          expression: "invitationMessage",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "invitations-message",
                        rows: "10",
                        value: "",
                        name: "invitation-message",
                        placeholder: "message",
                      },
                      domProps: { value: _vm.invitationMessage },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.invitationMessage = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "span",
                      {
                        staticClass: "btn btn-link",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.showInvitationFormClick.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v("Cancel\n              ")]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "btn  btn-info float-right",
                      attrs: { type: "submit", value: "Send" },
                    }),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showInvitationURLForm,
            expression: "showInvitationURLForm",
          },
        ],
        staticClass: "row",
      },
      [
        _c("div", { staticClass: "media col" }, [
          _c("div", { staticClass: "media-body" }, [
            _c("h4", [_vm._v("Invitation: " + _vm._s(_vm.getInvitationID()))]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group mb-3 pt-3" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "form-control" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.getInvitationEmail()) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group mb-3" }, [
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "form-control" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.getInvitationURL()) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "input-group-append" }, [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard",
                        value: function () {
                          return _vm.getInvitationURL()
                        },
                        expression: "() => getInvitationURL()",
                      },
                    ],
                    staticClass: "input-group-text",
                    attrs: { title: "copy to clipboard" },
                  },
                  [_c("i", { staticClass: "fas fa-copy" })]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group mb-3" }, [
              _vm._m(2),
              _vm._v(" "),
              _c("div", { staticClass: "form-control" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.getSelectedItemValidTo()) +
                    "\n          "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "btn btn-link",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.hideInvitation.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Cancel")]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "table",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showInvitationTable,
            expression: "showInvitationTable",
          },
        ],
        staticClass: "table mt-3",
      },
      [
        _vm._m(3),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.invitations, function (item) {
            return _c("tr", { key: item.id }, [
              _c("td", [_vm._v(_vm._s(item.email))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(item.status))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm._f("niceTime")(item.validTo)))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(_vm._f("niceTime")(item.updatedAt)))]),
              _vm._v(" "),
              _c("td", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-warning",
                    attrs: {
                      title: "resend invitation",
                      disabled: _vm.canResendInvitation(item),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.resendInvitation(item)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-retweet" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-info",
                    attrs: {
                      title: "show invitation",
                      disabled: _vm.canResendInvitation(item),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.showInvitation(item)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-eye" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-danger float-right",
                    attrs: { title: "delete invitation" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.deleteInvitation(item)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-trash-alt" })]
                ),
              ]),
            ])
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "fas fa-at" }),
        _vm._v("  email\n            "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "fas fa-link" }),
        _vm._v("  link\n            "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "fas fa-hourglass-half" }),
        _vm._v("  expire\n            "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_c("i", { staticClass: "fas fa-at" }), _vm._v(" email")]),
        _vm._v(" "),
        _c("th", [
          _c("i", { staticClass: "fas fa-thermometer-half" }),
          _vm._v(" status"),
        ]),
        _vm._v(" "),
        _c("th", [
          _c("i", { staticClass: "fas fa-hourglass-half" }),
          _vm._v(" expire"),
        ]),
        _vm._v(" "),
        _c("th", [
          _c("i", { staticClass: "fas fa-history" }),
          _vm._v(" updated "),
        ]),
        _vm._v(" "),
        _c("th", [
          _c("i", { staticClass: "fas fa-toolbox" }),
          _vm._v(" actions"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }