<template>
  <div
    id="home"
    class="col"
  >
    <div
      id="no-session-view"
      v-show="!isLoggedIn()"
      class="row shadow p-3 mb-5 ml-0 mr-0 bg-white rounded form-inline">
      <div class="media w-100">
        <img class="align-self-center w-25" src="/images/StandardsHub.svg" alt=""/>
        <div class="media-body welcome">
          <h1 class="text-uppercase font-weight-bold">Welcome to demo website</h1>
          <hr>
          <p class="font-weight-light mb-5">
            This is invitation only access place.
          </p>
          <div class="text-center">
            <a class="btn btn-primary btn-xl mx-auto" href="#/signin">SignIn to proceed</a>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="isLoggedIn()"
      class="row shadow p-3 mb-5 ml-0 mr-0 bg-white rounded form-inline">
      <div class="col-auto align-bottom">
        <h5 class="mt-1">
          <strong>Repository: </strong>
          <select
            v-model="selectedRepo"
            class="form-control"
            title="select repository"
            :required="true"
            @change="onRepoChage"
          >
            <option
              v-for="option in Object.keys(repoInfo.data)"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </h5>
      </div>
      <div class="col-auto">
        <h5 class="mt-1">
          <strong>branch: </strong>
          <select
            id="branch_id"
            v-model="selectedBranch"
            class="form-control"
            title="select branch"
            :required="true"
            @change="onBranchChage"
          >
            <option
              v-for="b in branches()"
              :key="b"
              :value="b"
            >
              {{ b }}
            </option>
          </select>
        </h5>
      </div>
    </div>
    <div
      v-show="isLoggedIn()"
      class="#list-revisions">
      <div class="card">
        <div class="card-header">
          <div class="container">
            <div class="row">
              <div class="col-1">
                <div class="pt-2">
                  <a
                    title="open help page"
                    target="_blank"
                    href="#"
                  >
                    <i class="fas fa-info-circle fa-lg" />
                  </a>
                </div>
              </div>
              <div class="col-3">
                <h2>List of Revisions</h2>
              </div>
              <div class="col-2">
                <button
                  type="button"
                  class="btn btn-link"
                  @click="onRefreshClick"
                >
                  <span>
                    <i
                      class="fas fa-sync-alt fa-2x"
                      :class="{'fa-spin': isLoading}"
                    />
                  </span>
                </button>
              </div>
              <div class="col-6">
                <div class="container">
                  <div class="row">
                    <div class="col">
                      <nav
                        aria-label="..."
                        class="float-right"
                      >
                        <ul class="pagination">
                          <li
                            class="page-item"
                            title="previous page"
                          >
                            <a
                              class="page-link"
                              href="#"
                              aria-label="Previous"
                              @click.prevent="onPreviousPage"
                            >
                              <span aria-hidden="true">&laquo;</span>
                              <span class="sr-only">Previous</span>
                            </a>
                          </li>
                          <li class="page-item" />
                          <li class="page-item active">
                            <span class="page-link">
                              {{ currentPageStr }}
                              <span class="sr-only">(current)</span>
                            </span>
                          </li>
                          <li class="page-item" />
                          <li
                            class="page-item"
                            title="next page"
                          >
                            <a
                              class="page-link"
                              href="#"
                              aria-label="Next"
                              @click.prevent="onNextPage"
                            >
                              <span aria-hidden="true">&raquo;</span>
                              <span class="sr-only">Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                    <div class="col pr-0">
                      <router-link
                        :to="diffViewLink"
                        title="compare two revisions"
                        class="btn float-right"
                        aria-pressed="false"
                        :class="{'btn-secondary': cannotCompare, 'btn-info': !cannotCompare, disabled: cannotCompare}"
                      >
                        Compare
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body p-0">
          <ul class="list-group list-group-flush">
            <li class="list-group-item text-info">
              <div class="container">
                <div class="row">
                  <div class="col-2">
                    revision hash
                  </div>
                  <div class="col-5">
                    merge message
                  </div>
                  <div class="col-4">
                    commiter
                  </div>
                  <div class="col">
                    diff
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <ul class="list-group list-group-flush">
            <li
              v-for="item in revisions"
              :key="item.id"
              class="list-group-item"
              :class="{'list-group-item-secondary': item.active}"
            >
              <div class="container">
                <div class="row">
                  <div class="col-2">
                    <h4>
                      <span v-if="item.status === 'DONE'">
                        <a
                          :href="linkToDoc(item)"
                          target="_blank"
                          title="show documents for revision"
                        >
                          {{ item.short_hash }}
                        </a>
                      </span>
                      <span
                        v-if="item.status !== 'DONE'"
                      >
                        {{ item.short_hash }}
                      </span>
                    </h4>
                  </div>
                  <div class="col-5">
                    <p>{{ item.subject }}</p>
                    <p><i>{{ item.message }}</i></p>
                  </div>
                  <div class="col-4">
                    <p :title="item.date">
                      <span><i>{{ item.author }}</i> </span>
                      commited
                      <strong> {{ item.date | niceTime }}</strong>
                    </p>
                  </div>
                  <div class="col d-flex align-items-end flex-column">
                    <div
                      v-if="item.status === 'DONE'"
                      class="material-switch pull-right"
                      title="select for comparation"
                      @click.prevent="onSelected(item)"
                    >
                      <input
                        :id="item._id"
                        v-model="item.active"
                        type="checkbox"
                        :name="item._id"
                      >
                      <label
                        :for="item._id"
                        class="bg-success"
                      />
                    </div>
                    <div
                      v-show="haveSession"
                      v-if="item.status === 'DONE'"
                      class="mt-auto"
                      title="select for comparation"
                      @click.prevent="onDeleteBtn(item)"
                    >
                      <a
                        role="button"
                        href="#"
                        class="btn-sm btn-danger text-whitei mt-auto"
                      >
                        <i class="fas fa-trash-alt" />
                      </a>
                    </div>
                    <a
                      v-if="item.status === 'NEW'"
                      href="#"
                      role="button"
                      class="btn-sm btn-primary text-white"
                      @click.prevent="onBuildBtn(item)"
                    >
                      <i
                        class="fas fa-cogs"
                        title="Build this version"
                      />
                    </a>
                    <div
                      v-if="item.status !== 'DONE' && item.status !== 'NEW'"
                      class="waiting text-warning"
                      title="Building in progress"
                    >
                      <i class="far fa-clock" />
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'

export default {
  name: 'Home',
  data () {
    return {
      revisions: [],
      firstSelected: null,
      secondSelected: null,
      isLoading: false,
      currentPage: 0,
      documentCounts: 0,
      branch: '',
      repo: '',
      repoInfo: {
        host: '',
        path: '',
        data: {}
      },
      isRefreshing: false,
      selectedBranch: '',
      selectedRepo: ''
    }
  },
  computed: {
    haveSession () {
      return !!this.$store.getters.currentUser
    },
    currentPageStr: function () {
      let totalPages = Math.ceil(this.documentCounts / 10)
      return this.currentPage ? this.currentPage + '/' + totalPages : '1/1'
    },
    cannotCompare: function () {
      return !(this.firstSelected && this.secondSelected)
    },
    diffViewLink: function () {
      return (this.firstSelected && this.secondSelected
        ? [
          'diff-view',
          encodeURIComponent(this.repo),
          this.branch,
          this.firstSelected.short_hash,
          'vs',
          this.secondSelected.short_hash
        ].join('/') : '')
    }
  },
  created () {
    this.$root.$on('branch-changed', this.onBranchChanged)
    this.$on('refreshList', this.onRefreshList)
  },
  activated () {
    var self = this
    Vue.axios('/api/repo-info')
      .then(function (repoInfo) {
        self.repoInfo = repoInfo.data
        self.selectedRepo = Object.keys(self.repoInfo.data)[0]
        self.selectedBranch = self.repoInfo.data[self.selectedRepo][0]
        self.onBranchChage()
        return true
      })
      .catch(function (e) {
        console.log(e.message)
      })
    this.$root.$emit('refreshList')
    setInterval(function () {
      this.fetchData(this.currentPage-1 > 0 ? this.currentPage -1 : 0)
    }.bind(this), 30000)
  },
  methods: {
    isLoggedIn () {
      return !!this.$store.getters.currentUser
    },
    branches: function () {
      if (this.selectedRepo) {
        return this.repoInfo.data[this.selectedRepo]
      } else {
        return []
      }
    },
    onRepoChage: function () {
      if (this.selectedRepo) {
        this.selectedBranch = this.repoInfo.data[this.selectedRepo][0]
        this.onBranchChage()
      }
    },
    onBranchChage: function () {
      this.$root.$emit('branch-changed', { repo: this.selectedRepo, branch: this.selectedBranch })
    },
    onBranchChanged: function (data) {
      if (data && data.branch && data.repo) {
        this.branch = data.branch.toString()
        this.repo = data.repo.toString()
        this.fetchData(0)
      }
    },
    onRefreshClick : function() {
      this.fetchData(this.currentPage - 1)
    },
    fetchData: function (page) {
      var self = this
      this.isLoading = !this.isLoading
      if (this.branch.length > 0 &&
        this.repo.length > 0) {
        Vue.axios.get('/api/revisions', { params: { repo: this.repo, branch: this.branch, page: page || 0 } })
          .then(function (response) {
            self.isLoading = !self.isLoading
            self.revisions = response.data.data.docs.map(function (item) {
              item.active = false
              return item
            })
            self.currentPage = parseInt(response.data.meta.currentPage) + 1
            self.documentCounts = parseInt(response.data.meta.documentCounts)
            return true
          })
          .catch(function (error) {
            self.isLoading = !self.isLoading
            console.log(error)
          })
      } else {
        console.log('Branch is not defined')
        self.currentPage = 1
        self.documentCounts = 0
        this.isLoading = !this.isLoading
      }
    },
    onPreviousPage: function () {
      if (this.currentPage - 1 > 0) {
        this.fetchData(this.currentPage - 2)
      }
    },
    onNextPage: function () {
      if ((this.currentPage) * 10 < this.documentCounts) {
        this.fetchData(this.currentPage)
      }
    },
    onUpdateClick: function () {
      this.fetchData()
    },
    linkToDoc: function (item) {
      return '/docs/' + item.repo + '/' + item.branch + '/' + item.short_hash
    },
    onSelected: function (item) {
      item.active = !item.active
      if (item.active) {
        if (this.firstSelected && this.secondSelected) {
          this.firstSelected.active = false
          this.firstSelected = this.secondSelected
          this.secondSelected = item
        } else if (this.firstSelected) {
          this.secondSelected = item
        } else {
          this.firstSelected = item
        }
      } else {
        if (this.firstSelected && this.firstSelected._id === item._id) {
          this.firstSelected = this.secondSelected
          this.secondSelected = null
        } else if (this.secondSelected && this.secondSelected._id === item._id) {
          this.secondSelected = null
        }
      }
    },
    onBuildBtn: function (item) {
      Vue.axios.put('/api/build/' + item.id)
        .then(result => {
          if (result.data.data.status) {
            let status = result.data.data.status
            item.status = status
          }
          return true
        })
        .catch(e => {
          console.error(e)
        })
    },
    onDeleteBtn: function (item) {
      var self = this
      self.isRefreshing = true
      item.active = false
      if (this.firstSelected && this.firstSelected._id === item._id) {
        this.firstSelected = this.secondSelected
        this.secondSelected = null
      } else if (this.secondSelected && this.secondSelected._id === item._id) {
        this.secondSelected = null
      }
      Vue.axios({
        method: 'DELETE',
        url: '/api/revisions/' + item.id,
        headers: {
          'Authorization': 'Bearer ' + self.$store.getters.currentUser.token
        }
      })
        .then(function (response) {
          console.log(response.data)
          item.status = response.data.data.status
          self.isRefreshing = false
          return self.isRefreshing
        })
        .catch(function (e) {
          console.log(e.message)
          self.isRefreshing = false
        })
    },
    onRefreshList: function () {
      this.$root.$emit('branch-changed', { repo: this.selectedRepo, branch: this.selectedBranch })
    }
  }
}
</script>
<style lang="css">
</style>
