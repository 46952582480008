var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col", attrs: { id: "home" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoggedIn(),
            expression: "!isLoggedIn()",
          },
        ],
        staticClass:
          "row shadow p-3 mb-5 ml-0 mr-0 bg-white rounded form-inline",
        attrs: { id: "no-session-view" },
      },
      [_vm._m(0)]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoggedIn(),
            expression: "isLoggedIn()",
          },
        ],
        staticClass:
          "row shadow p-3 mb-5 ml-0 mr-0 bg-white rounded form-inline",
      },
      [
        _c("div", { staticClass: "col-auto align-bottom" }, [
          _c("h5", { staticClass: "mt-1" }, [
            _c("strong", [_vm._v("Repository: ")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedRepo,
                    expression: "selectedRepo",
                  },
                ],
                staticClass: "form-control",
                attrs: { title: "select repository", required: true },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedRepo = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.onRepoChage,
                  ],
                },
              },
              _vm._l(Object.keys(_vm.repoInfo.data), function (option) {
                return _c(
                  "option",
                  { key: option, domProps: { value: option } },
                  [_vm._v("\n            " + _vm._s(option) + "\n          ")]
                )
              }),
              0
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-auto" }, [
          _c("h5", { staticClass: "mt-1" }, [
            _c("strong", [_vm._v("branch: ")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedBranch,
                    expression: "selectedBranch",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "branch_id",
                  title: "select branch",
                  required: true,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedBranch = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.onBranchChage,
                  ],
                },
              },
              _vm._l(_vm.branches(), function (b) {
                return _c("option", { key: b, domProps: { value: b } }, [
                  _vm._v("\n            " + _vm._s(b) + "\n          "),
                ])
              }),
              0
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoggedIn(),
            expression: "isLoggedIn()",
          },
        ],
        staticClass: "#list-revisions",
      },
      [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row" }, [
                _vm._m(1),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "col-2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-link",
                      attrs: { type: "button" },
                      on: { click: _vm.onRefreshClick },
                    },
                    [
                      _c("span", [
                        _c("i", {
                          staticClass: "fas fa-sync-alt fa-2x",
                          class: { "fa-spin": _vm.isLoading },
                        }),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "container" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c(
                          "nav",
                          {
                            staticClass: "float-right",
                            attrs: { "aria-label": "..." },
                          },
                          [
                            _c("ul", { staticClass: "pagination" }, [
                              _c(
                                "li",
                                {
                                  staticClass: "page-item",
                                  attrs: { title: "previous page" },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "page-link",
                                      attrs: {
                                        href: "#",
                                        "aria-label": "Previous",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.onPreviousPage.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { attrs: { "aria-hidden": "true" } },
                                        [_vm._v("«")]
                                      ),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v("Previous"),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("li", { staticClass: "page-item" }),
                              _vm._v(" "),
                              _c("li", { staticClass: "page-item active" }, [
                                _c("span", { staticClass: "page-link" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.currentPageStr) +
                                      "\n                            "
                                  ),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v("(current)"),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("li", { staticClass: "page-item" }),
                              _vm._v(" "),
                              _c(
                                "li",
                                {
                                  staticClass: "page-item",
                                  attrs: { title: "next page" },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "page-link",
                                      attrs: {
                                        href: "#",
                                        "aria-label": "Next",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.onNextPage.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { attrs: { "aria-hidden": "true" } },
                                        [_vm._v("»")]
                                      ),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "sr-only" }, [
                                        _vm._v("Next"),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col pr-0" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "btn float-right",
                              class: {
                                "btn-secondary": _vm.cannotCompare,
                                "btn-info": !_vm.cannotCompare,
                                disabled: _vm.cannotCompare,
                              },
                              attrs: {
                                to: _vm.diffViewLink,
                                title: "compare two revisions",
                                "aria-pressed": "false",
                              },
                            },
                            [
                              _vm._v(
                                "\n                      Compare\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body p-0" }, [
            _vm._m(3),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "list-group list-group-flush" },
              _vm._l(_vm.revisions, function (item) {
                return _c(
                  "li",
                  {
                    key: item.id,
                    staticClass: "list-group-item",
                    class: { "list-group-item-secondary": item.active },
                  },
                  [
                    _c("div", { staticClass: "container" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-2" }, [
                          _c("h4", [
                            item.status === "DONE"
                              ? _c("span", [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: _vm.linkToDoc(item),
                                        target: "_blank",
                                        title: "show documents for revision",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(item.short_hash) +
                                          "\n                      "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            item.status !== "DONE"
                              ? _c("span", [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(item.short_hash) +
                                      "\n                    "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-5" }, [
                          _c("p", [_vm._v(_vm._s(item.subject))]),
                          _vm._v(" "),
                          _c("p", [_c("i", [_vm._v(_vm._s(item.message))])]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-4" }, [
                          _c("p", { attrs: { title: item.date } }, [
                            _c("span", [
                              _c("i", [_vm._v(_vm._s(item.author))]),
                            ]),
                            _vm._v(
                              "\n                    commited\n                    "
                            ),
                            _c("strong", [
                              _vm._v(
                                " " + _vm._s(_vm._f("niceTime")(item.date))
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col d-flex align-items-end flex-column",
                          },
                          [
                            item.status === "DONE"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "material-switch pull-right",
                                    attrs: { title: "select for comparation" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.onSelected(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.active,
                                          expression: "item.active",
                                        },
                                      ],
                                      attrs: {
                                        id: item._id,
                                        type: "checkbox",
                                        name: item._id,
                                      },
                                      domProps: {
                                        checked: Array.isArray(item.active)
                                          ? _vm._i(item.active, null) > -1
                                          : item.active,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = item.active,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  item,
                                                  "active",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  item,
                                                  "active",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(item, "active", $$c)
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("label", {
                                      staticClass: "bg-success",
                                      attrs: { for: item._id },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.status === "DONE"
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.haveSession,
                                        expression: "haveSession",
                                      },
                                    ],
                                    staticClass: "mt-auto",
                                    attrs: { title: "select for comparation" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.onDeleteBtn(item)
                                      },
                                    },
                                  },
                                  [_vm._m(4, true)]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.status === "NEW"
                              ? _c(
                                  "a",
                                  {
                                    staticClass:
                                      "btn-sm btn-primary text-white",
                                    attrs: { href: "#", role: "button" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.onBuildBtn(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-cogs",
                                      attrs: { title: "Build this version" },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.status !== "DONE" && item.status !== "NEW"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "waiting text-warning",
                                    attrs: { title: "Building in progress" },
                                  },
                                  [_c("i", { staticClass: "far fa-clock" })]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "media w-100" }, [
      _c("img", {
        staticClass: "align-self-center w-25",
        attrs: { src: "/images/StandardsHub.svg", alt: "" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "media-body welcome" }, [
        _c("h1", { staticClass: "text-uppercase font-weight-bold" }, [
          _vm._v("Welcome to demo website"),
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("p", { staticClass: "font-weight-light mb-5" }, [
          _vm._v("\n          This is invitation only access place.\n        "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-center" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-primary btn-xl mx-auto",
              attrs: { href: "#/signin" },
            },
            [_vm._v("SignIn to proceed")]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-1" }, [
      _c("div", { staticClass: "pt-2" }, [
        _c(
          "a",
          { attrs: { title: "open help page", target: "_blank", href: "#" } },
          [_c("i", { staticClass: "fas fa-info-circle fa-lg" })]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("h2", [_vm._v("List of Revisions")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "list-group list-group-flush" }, [
      _c("li", { staticClass: "list-group-item text-info" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-2" }, [
              _vm._v("\n                  revision hash\n                "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-5" }, [
              _vm._v("\n                  merge message\n                "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-4" }, [
              _vm._v("\n                  commiter\n                "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col" }, [
              _vm._v("\n                  diff\n                "),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn-sm btn-danger text-whitei mt-auto",
        attrs: { role: "button", href: "#" },
      },
      [_c("i", { staticClass: "fas fa-trash-alt" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }