<template>
  <div
    id="signup"
    class="col d-flex flex-column justify-content-center"
  >
    <div class="signup-content w-50">
      <div class="text-center pb-3">
        <span class="display-4">Sign up</span>
      </div>
      <div
        v-if="message"
        class="alert alert-info"
        role="alert"
      >
        {{ message }}
        <button
          type="button"
          class="close"
          aria-label="Close"
          @click.prevent="onMessageAlertClose"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        v-if="errors"
        class="alert alert-danger"
        role="alert"
      >
        {{ errors }}
        <button
          type="button"
          class="close"
          aria-label="Close"
          @click.prevent="onErrorAlertClose"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form
        class="validate-form"
        @submit.prevent="onSignUp"
      >
        <div class="form-group">
          <input
            id="singUpName"
            v-model="signUpName"
            disabled
            class="w-100"
            required
            type="email"
            value=""
            name="signUpName"
            placeholder="email"
            autocomplete="username"
          >
        </div>
        <div class="form-group">
          <input
            id="signUpPassword"
            v-model="signUpPassword"
            class="w-100"
            required
            type="password"
            value=""
            name="signUpPassword"
            placeholder="password"
            autocomplete="new-password"
          >
        </div>
        <div class="form-group">
          <input
            id="signUpPasswordCopy"
            v-model="signUpPasswordCopy"
            class="w-100"
            required
            type="password"
            value=""
            name="signUpPasswordCopy"
            placeholder="confirm password"
            autocomplete="new-password"
          >
        </div>
        <div class="form-group">
          <input
            class="w-100"
            type="submit"
            value="SignUp"
          >
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'

export default {
  name: 'Signup',
  data () {
    return {
      signUpName: '',
      signUpPassword: '',
      signUpPasswordCopy: '',
      message: '',
      errors: null
    }
  },
  activated () {
    this.fetchData()
  },
  methods: {
    onSignUp () {
      let self = this

      Vue.axios({
        method: 'POST',
        url: '/signup',
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          user: {token: self.$route.query.token, password: this.signUpPassword}
        }
      })
        .then(result => {
          console.log(result.data)
          if (result.data) {
            self.$store.dispatch('createSession', result.data)
            self.$router.push(self.$route.query.redirect || { name: 'home' })
          }
          return true
        })
        .catch(e => {
          console.log(e)
        })

    },
    fetchData () {
      let self = this

      Vue.axios({
        method: 'GET',
        url: '/signup?token=' + self.$route.query.token,
      })
        .then(result => {
          console.log(result.data.data)
          if (result.data && result.data.data) {
            self.signUpName = result.data.data.email
          }
          return true
        })
        .catch(e => {
          console.log(e)
        })
    }
  }
}
</script>
<style lang="css">
</style>
