<template>
  <div
    id="Signin"
    class="col d-flex flex-column justify-content-center"
  >
    <div class="signup-content w-50">
      <div class="text-center pb-3">
        <span class="display-4">Sign In</span>
      </div>
      <div
        v-if="message"
        class="alert alert-info"
        role="alert"
      >
        { {message }}
        <button
          type="button"
          class="close"
          aria-label="Close"
          @click.prevent="onMessageAlertClose"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        v-if="errors"
        class="alert alert-danger"
        role="alert"
      >
        {{ errors }}
        <button
          type="button"
          class="close"
          aria-label="Close"
          @click.prevent="onErrorAlertClose"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form
        class="validate-form"
        @submit.prevent="onSignIn"
      >
        <div class="form-group">
          <input
            id="singInName"
            v-model="signInName"
            class="w-100"
            required
            type="email"
            value=""
            name="signInName"
            placeholder="email"
            autocomplete="username"
          >
        </div>
        <div class="form-group">
          <input
            id="signInPassword"
            v-model="signInPassword"
            class="w-100"
            required
            type="password"
            value=""
            name="signInPassword"
            placeholder="password"
            autocomplete="new-password"
          >
        </div>
        <div class="form-group">
          <input
            class="w-100"
            type="submit"
            value="SignIn"
          >
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'

export default {
  name: 'Signin',
  data () {
    return {
      signInName: '',
      signInPassword: '',
      message: '',
      errors: ''
    }
  },
  activated () {
    this.clearMessage()
    this.clearErrors()
    this.singInName = ''
    this.signInPassword = ''
  },
  methods: {
    clearMessage () {
      this.message = ''
    },
    clearErrors () {
      this.errors = ''
    },
    onMessageAlertClose () {
      this.clearMessage()
    },
    onErrorAlertClose () {
      this.clearErrors()
    },
    onSignIn () {
      this.clearMessage()
      this.clearErrors()
      var self = this

      if (this.signInName && this.signInPassword &&
        this.signInName.length > 0 && this.signInPassword.length > 0) {
        Vue.axios({
          url: '/signin',
          method: 'post',
          data: {
            user: {
              email: this.signInName,
              password: this.signInPassword
            }
          }
        })
          .then(data => {
            console.log(data.data)
            self.$store.dispatch('createSession', data.data)
            return self.$router.push(self.$route.query.redirect || { name: 'home' })
          })
          .catch(e => {
            if (e.response) {
              if (e.response.status === 400) {
                self.errors = e.response.data.errors.message
              } else {
                self.errors = e.response.statusText
              }
            }
          })
      }
    }
  }
}
</script>
<style lang="css">
</style>
