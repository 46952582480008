<template>
  <div
    id="repositories"
    class="col"
  >
    <h2>Repositories</h2>
    <form
      class="shadow rounded p-3 mt-5 mb-5"
      @submit.prevent="onAddNewRepository"
    >
      <div class="form-row">
        <div class="col-md-6">
          <input
            id="repository"
            v-model="newRepository"
            class="form-control"
            type="text"
            name="repository"
            title="repository to add"
            value=""
            placeholder="Type Github repository"
          >
        </div>
        <div class="col-md-4">
          <input
            id="branch"
            v-model="newBranch"
            class="form-control"
            type="text"
            name="branch"
            title="branch name"
            value=""
            placeholder="Type branch name"
          >
        </div>
        <div class="col">
          <button
            class="btn btn-primary float-right"
            title="Add repository"
            @click.prevent="onAddNewRepository"
          >
            <i class="fas fa-plus-circle" />
          </button>
        </div>
      </div>
    </form>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">
            <i class="fab fa-github" /> repository
          </th>
          <th>
            <i class="fas fa-code-branch" /> branch
          </th>
          <th>
            <i class="fas fa-toolbox" /> actions
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in repositories"
          :key="item.id"
        >
          <td>{{ item.name }}</td>
          <td>{{ item.branch }}</td>
          <td>
            <button
              title="DELETE repository/branch!!!"
              class="btn btn-danger btn-sm"
              @click.prevent="onDeleteRepository(item)"
            >
              <i class="fas fa-trash-alt" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import Vue from 'vue'

export default {
  name: 'Repositories',
  data () {
    return {
      repositories: [],
      newRepository: '',
      newBranch: ''
    }
  },
  activated: function () {
    this.fetchData()
  },
  methods: {
    onDeleteRepository: function (data) {
      console.log(data)
      var self = this

      Vue.axios({
        url: '/api/repositories/' + data.id,
        method: 'DELETE',
        headers: {
          'Authorization': 'Bearer ' + self.$store.getters.currentUser.token
        }
      })
        .then(result => {
          console.log(result)
          self.fetchData()
          return true
        })
        .catch(function (e) {
          console.log(e.message)
          self.fetchData()
        })
    },
    onAddNewRepository: function () {
      console.log(this.newRepository, this.newBranch)
      var self = this

      Vue.axios({
        url: '/api/repositories',
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + self.$store.getters.currentUser.token
        },
        data: {
          repository: this.newRepository,
          branch: this.newBranch
        }
      })
        .then(result => {
          console.log(result)
          self.newRepository = ''
          self.newBranch = ''
          return self.fetchData()
        })
        .catch(function (e) {
          console.log(e.message)
          self.newRepository = ''
          self.newBranch = ''
        })
    },
    fetchData: function () {
      var self = this

      Vue.axios({
        url: '/api/repositories',
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + self.$store.getters.currentUser.token
        }
      })
        .then(function (result) {
          if (result.data) {
            if (result.data.data) {
              self.repositories = result.data.data.repositories
            } else if (result.data.errors) {
              self.errors = result.data.errors
            } else {
              console.log('this should not happen !?')
            }
          }
          return true
        })
        .catch(function (e) {
          console.log(e.message)
        })
    }
  }
}
</script>
<style lang="css">
</style>
